/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      repeat
      repeat_id
      description
      status
      payment_successfull
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      repeat
      repeat_id
      description
      status
      payment_successfull
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      repeat
      repeat_id
      description
      status
      payment_successfull
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEventPublic = /* GraphQL */ `
  mutation CreateEventPublic(
    $input: CreateEventPublicInput!
    $condition: ModelEventPublicConditionInput
  ) {
    createEventPublic(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEventPublic = /* GraphQL */ `
  mutation UpdateEventPublic(
    $input: UpdateEventPublicInput!
    $condition: ModelEventPublicConditionInput
  ) {
    updateEventPublic(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEventPublic = /* GraphQL */ `
  mutation DeleteEventPublic(
    $input: DeleteEventPublicInput!
    $condition: ModelEventPublicConditionInput
  ) {
    deleteEventPublic(input: $input, condition: $condition) {
      event_id
      title
      court_id
      start
      end
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      text
      order
      createdAt
      updatedAt
      __typename
    }
  }
`;
