import { Auth } from "aws-amplify";
import { useAuthenticator, Authenticator } from "@aws-amplify/ui-react";
import AdminView from "./components/AdminView";
import UserView from "./components/UserView";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import RoomIcon from "@mui/icons-material/Room";
import PhoneIcon from "@mui/icons-material/Phone";
import ScheduleIcon from "@mui/icons-material/Schedule";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import { API, graphqlOperation } from "aws-amplify";
import { createMessage as createMessageGQL, deleteMessage as deleteMessageGQL } from "./graphql/mutations";
import { listMessages } from "./graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/auth";

import { moveItemInArray, modifyOrPrependItem, deleteItem } from "./common/functions";

import "./App.css";
import logo from "./images/logo.png";
import { useState, useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Альтаїр - Тенісний Клуб";
  }, []);

  const { authStatus } = useAuthenticator((context) => [context]);
  const { route } = useAuthenticator((context) => [context.route]);
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const deleteAccount = async () => {
    try {
      const result = await Auth.deleteUser();
      console.log(result);
    } catch (error) {
      console.log("Error deleting user", error);
    }
    setSettingsDialogVisible(false);
    return signOut();
  };
  const [authPopupVisible, setAuthPopupVisible] = useState(false);
  const [privacyPolicyPopupVisible, setPrivacyPolicyPopupVisible] = useState(
    window.location.hash.substring(1) === "privacy-policy"
  );
  const initialConfirmDialog = {
    show: false,
    title: "",
    content: "",
    callback: null,
  };
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(initialConfirmDialog);
  const [settingsDialogVisible, setSettingsDialogVisible] = useState(false);

  // const backendMessages = [
  //   {
  //     id: 1,
  //     text: "🎾 ВІДКРИТО НАБІР У ГРУПИ ВІД 4 ДО 10 р.",
  //     order: 1,
  //   },
  //   {
  //     id: 2,
  //     text: "🥎 Оренда кортів",
  //     order: 2,
  //   },
  //   {
  //     id: 3,
  //     text: "🏆 Тренуємо дітей та дорослих",
  //     order: 3,
  //   },
  //   {
  //     id: 4,
  //     text: "👍 Перше заняття для дітей БЕЗКОШТОВНО",
  //     order: 4,
  //   },
  // ];

  const [messages, setMessages] = useState([]);

  const isAdmin =
    authStatus === "authenticated" &&
    user?.getSignInUserSession().idToken.payload["cognito:groups"] &&
    user?.getSignInUserSession().idToken.payload["cognito:groups"].includes("admins");

  useEffect(() => {
    if (route !== "idle" && authStatus !== "configuring") {
      (async () => {
        const messagesData = await API.graphql(
          !isAdmin
            ? {
                ...graphqlOperation(listMessages),
                authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
              }
            : {
                ...graphqlOperation(listMessages),
              }
        );
        const backendMessages = messagesData.data.listMessages.items;
        backendMessages.sort((a, b) => a.order - b.order);
        setMessages(backendMessages);
      })();
    }
  }, [route, authStatus, isAdmin]);

  const updateMessages = async () => {
    await Promise.all(
      messages.map(async (message) => {
        if (message.id) {
          await API.graphql(
            graphqlOperation(deleteMessageGQL, {
              input: {
                id: message.id,
              },
            })
          );
        }
        delete message.id;
        await API.graphql(graphqlOperation(createMessageGQL, { input: { text: message.text, order: message.order } }));
      })
    );
    const messagesData = await API.graphql(graphqlOperation(listMessages));
    const backendMessages = messagesData.data.listMessages.items;
    backendMessages.sort((a, b) => a.order - b.order);
    setMessages(backendMessages);
  };

  const [editMessage, setEditMessage] = useState({
    text: "",
    order: 0,
  });

  const saveMessage = (message) => {
    setMessages(modifyOrPrependItem(messages, message));
    setEditMessage({
      text: "",
      order: 0,
    });
  };

  const deleteMessage = (message) => {
    (async () => {
      if (message.id) {
        await API.graphql(
          graphqlOperation(deleteMessageGQL, {
            input: {
              id: message.id,
            },
          })
        );
      }
    })();
    setMessages(deleteItem(messages, message));
    setEditMessage({
      text: "",
      order: 0,
    });
  };

  const [messagePopupVisible, setMessagePopupVisible] = useState(false);

  useEffect(() => {
    if (authStatus === "authenticated") {
      setAuthPopupVisible(false);
    }
  }, [authStatus]);

  const formFields = {
    signIn: {
      username: {
        dialCodeList: ["+380"],
      },
    },
    signUp: {
      phone_number: {
        dialCodeList: ["+380"],
      },
    },
  };

  return route !== "idle" && authStatus !== "configuring" ? (
    <div className="App">
      {authPopupVisible ? (
        <Dialog
          onClose={() => {
            setAuthPopupVisible(false);
          }}
          open
        >
          <Authenticator formFields={formFields} />
        </Dialog>
      ) : (
        ""
      )}
      {privacyPolicyPopupVisible ? (
        <Dialog
          onClose={() => {
            setPrivacyPolicyPopupVisible(false);
            window.location.hash = "";
          }}
          open
        >
          <IconButton
            aria-label="close"
            onClick={() => {
              setPrivacyPolicyPopupVisible(false);
              window.location.hash = "";
            }}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ height: "90vh", padding: 20 }}>
            <h1>Політика конфіденційності</h1>

            <p>
              <i>Останнє оновлення: 27 жовтня 2023 р.</i>
            </p>

            <p>
              Дякуємо, що відвідали наш веб-сайт. Ми поважаємо вашу конфіденційність і прагнемо захищати вашу особисту
              інформацію. Ця Політика конфіденційності пояснює, як ми збираємо, використовуємо та захищаємо ваші дані.
              Використовуючи наш веб-сайт, ви погоджуєтеся з умовами цієї політики.
            </p>

            <h3>Збір даних</h3>

            <p>Ми збираємо такі типи інформації від користувачів в Україні:</p>

            <p>
              <b>Ім'я</b>: ми збираємо ваше ім'я, коли ви надаєте його добровільно, наприклад, під час заповнення
              контактної форми або створення облікового запису.
            </p>

            <p>
              <b>Адреса електронної пошти</b>: ми збираємо вашу електронну адресу, щоб спілкуватися з вами, надсилати
              оновлення, відповідати на запити та сповіщення, пов’язані з обліковим записом.
            </p>

            <p>
              <b>Номер телефону</b>: ми можемо отримати ваш номер телефону з метою перевірки, надання підтримки клієнтів
              або зв’язку, пов’язаного з обслуговуванням.
            </p>

            <p>
              <b>Платіжна інформація</b>: коли ви здійснюєте платіж на нашому веб-сайті, ми збираємо платіжну
              інформацію, таку як дані кредитної картки або іншу фінансову інформацію, необхідну для обробки транзакції.
              Зверніть увагу, що ми не зберігаємо вашу повну платіжну інформацію. Для обробки цих транзакцій ми
              використовуємо безпечні служби обробки платежів.
            </p>

            <h3>Використання даних</h3>

            <p>Ми використовуємо зібрану інформацію для таких цілей:</p>

            <p>Надавати та підтримувати функціональність і послуги веб-сайту.</p>

            <p>Щоб персоналізувати роботу користувача.</p>

            <p>
              Для спілкування з вами, зокрема відповідей на запити, надання підтримки клієнтів і надсилання важливих
              оновлень.
            </p>

            <p>Для обробки платежів і виконання ваших замовлень, якщо це можливо.</p>

            <p>Для дотримання юридичних зобов’язань, вирішення суперечок і забезпечення дотримання нашої політики.</p>

            <h3>Захист даних</h3>

            <p>
              Ми серйозно ставимося до захисту даних. Ми вживаємо розумні заходи безпеки, щоб захистити вашу особисту
              інформацію від несанкціонованого доступу, розголошення, зміни або знищення. Однак майте на увазі, що жоден
              метод передачі через Інтернет чи електронне зберігання не є повністю безпечним, і ми не можемо гарантувати
              абсолютну безпеку ваших даних.
            </p>

            <h3>Сторонні служби</h3>

            <p>
              Ми можемо використовувати служби та інструменти третіх сторін, щоб покращити функціональність нашого
              веб-сайту, наприклад аналітику, рекламу чи обробку платежів. Ці служби можуть збирати та використовувати
              інформацію відповідно до своєї політики конфіденційності. Ми не несемо відповідальності за роботу цих
              сторонніх служб.
            </p>

            <h3>Зберігання даних</h3>

            <p>
              Ми зберігатимемо вашу особисту інформацію стільки, скільки необхідно для досягнення цілей, викладених у
              цій Політиці конфіденційності, якщо довший період зберігання не вимагається або не дозволений законом.
            </p>

            <h3>Зміни в цій Політиці конфіденційності</h3>

            <p>
              Час від часу ми можемо оновлювати цю Політику конфіденційності. Будь-які зміни набудуть чинності після
              публікації оновленої політики на нашому веб-сайті.
            </p>

            <h3>Зв'яжіться з нами</h3>

            <p>
              Якщо у вас є запитання чи сумніви щодо цієї Політики конфіденційності, зв’яжіться з нами за адресою
              roma@slobodian.info.
            </p>

            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </Dialog>
      ) : (
        ""
      )}
      {confirmDialogVisible.show ? (
        <Dialog
          onClose={() => {
            setConfirmDialogVisible(initialConfirmDialog);
          }}
          open
        >
          {confirmDialogVisible.title ? (
            <DialogTitle id="alert-dialog-title">{confirmDialogVisible.title}</DialogTitle>
          ) : (
            ""
          )}
          {confirmDialogVisible.content ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">{confirmDialogVisible.content}</DialogContentText>
            </DialogContent>
          ) : (
            ""
          )}
          <DialogActions>
            <Button
              onClick={() => {
                setConfirmDialogVisible(initialConfirmDialog);
              }}
            >
              Ні
            </Button>
            <Button
              onClick={() => {
                if (confirmDialogVisible.callback) {
                  confirmDialogVisible.callback();
                }
                setConfirmDialogVisible(initialConfirmDialog);
              }}
            >
              Так
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
      {settingsDialogVisible ? (
        <Dialog
          onClose={() => {
            setSettingsDialogVisible(false);
          }}
          open
        >
          <DialogTitle>Мій Аккаунт</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ marginTop: 1 }}>
              <Typography variant="p">
                <b>Імʼя:</b> {user.attributes.name}
              </Typography>
            </DialogContentText>
            <DialogContentText sx={{ marginTop: 1 }}>
              <Typography variant="p">
                <b>Email:</b> {user.attributes.email}
              </Typography>
            </DialogContentText>
            <DialogContentText sx={{ marginTop: 1 }}>
              <Typography variant="p">
                <b>Телефон:</b> {user.attributes.phone_number}
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <Button
                variant="outlined"
                onClick={() => {
                  setConfirmDialogVisible({
                    show: true,
                    title: "Ви точно хочете видалити аккаунт?",
                    callback: deleteAccount,
                  });
                }}
                color="error"
                style={{ marginTop: 10 }}
              >
                Видалити Аккаунт
              </Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setSettingsDialogVisible(false);
              }}
            >
              Закрити
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}

      {messagePopupVisible ? (
        <Dialog
          onClose={() => {
            setEditMessage({
              text: "",
              order: 0,
            });
            setMessagePopupVisible(false);
          }}
          open
        >
          <DialogContent>
            <DialogContentText sx={{ marginTop: 1, marginBottom: 1 }}>
              <TextareaAutosize
                style={{ width: "100%", fontSize: 14, padding: 10, border: "1px solid #ccc", borderRadius: 5 }}
                aria-label="minimum height"
                minRows={2}
                placeholder="Введіть текст"
                value={editMessage.text}
                onChange={(e) => setEditMessage({ ...editMessage, text: e.target.value })}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditMessage({
                  text: "",
                  order: 0,
                });
                setMessagePopupVisible(false);
              }}
            >
              Закрити
            </Button>
            {editMessage.id ? (
              <Button
                onClick={() => {
                  setConfirmDialogVisible({
                    show: true,
                    title: "Ви точно хочете видалити текст?",
                    callback: () => {
                      deleteMessage(editMessage);
                      setMessagePopupVisible(false);
                    },
                  });
                }}
              >
                Видалити
              </Button>
            ) : (
              ""
            )}
            <Button
              onClick={() => {
                saveMessage(editMessage);
                setMessagePopupVisible(false);
              }}
            >
              Зберегти
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}

      <div className="App-header">
        <AppBar position="static">
          <Toolbar variant="dense">
            <img src={logo} height={80} alt="logo" />
            <Typography variant="h6" color="inherit" fontSize={13} sx={{ maxWidth: 180 }} component="span">
              {authStatus === "authenticated" && user && user.attributes ? `Привіт, ${user.attributes.name}` : ""}{" "}
            </Typography>
            <Typography variant="h6" color="inherit" fontSize={16} component="span" sx={{ marginLeft: "auto" }}>
              {route === "authenticated" ? (
                <>
                  <Button
                    variant="text"
                    onClick={() => {
                      setSettingsDialogVisible(true);
                    }}
                    color="inherit"
                    style={{ fontWeight: 900, minWidth: 40 }}
                  >
                    <SettingsIcon />
                  </Button>
                  <Button
                    variant="text"
                    onClick={() => {
                      setConfirmDialogVisible({
                        show: true,
                        title: "Ви точно хочете вийти?",
                        callback: signOut,
                      });
                    }}
                    color="inherit"
                    style={{ fontWeight: 900, minWidth: 40 }}
                  >
                    <LogoutIcon />
                  </Button>
                </>
              ) : (
                <Button
                  variant="text"
                  color="inherit"
                  onClick={() => {
                    setAuthPopupVisible(true);
                  }}
                  style={{ fontWeight: 900 }}
                >
                  <LoginIcon />
                </Button>
              )}
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
      <div className="App-info">
        <Accordion>
          <AccordionSummary expandIcon={<FontAwesomeIcon icon={faChevronDown} />}>
            <Typography>Інфо</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 1 }}>
            <Grid container spacing={0.5}>
              <Grid item xs={6}>
                <Card>
                  <CardContent sx={{ padding: 1, paddingBottom: "15px!important" }}>
                    <Typography variant="subtitle2" align="center" gutterBottom fontWeight={700}>
                      Тенісний Клуб Альтаїр
                    </Typography>

                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                      <RoomIcon color="primary" style={{ marginRight: "8px" }} />
                      <Typography variant="body2" align="left">
                        м. Хмельницький, вул. Чорновола, 88
                      </Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                      <PhoneIcon color="primary" style={{ marginRight: "8px" }} />
                      <Typography variant="body2">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: "#1876d2", fontWeight: "700" }}
                          href="tel:%2B380684141553"
                        >
                          +38 (068) 414-15-53
                        </a>
                      </Typography>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginBottom: 10 }}>
                      <ScheduleIcon color="primary" style={{ marginRight: "8px" }} />
                      <Typography variant="body2" color="textSecondary">
                        Пн-Нд 7:00-21:00
                      </Typography>
                    </div>

                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      href="https://www.google.com/maps/dir/?api=1&destination=%D0%A2%D0%B5%D0%BD%D1%96%D1%81%D0%BD%D0%B8%D0%B9%20%D0%BA%D0%BB%D1%83%D0%B1%20%D0%90%D0%BB%D1%8C%D1%82%D0%B0%D1%97%D1%80%20/%20%D0%92%D0%B5%D0%BB%D0%B8%D0%BA%D0%B8%D0%B9%20%D1%82%D0%B5%D0%BD%D1%96%D1%81%20%D1%83%20%D0%A5%D0%BC%D0%B5%D0%BB%D1%8C%D0%BD%D0%B8%D1%86%D1%8C%D0%BA%D0%BE%D0%BC%D1%83"
                      target="_system"
                    >
                      Відкрити в картах
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6} sx={{ maxHeight: 200, overflowY: "scroll" }}>
                {isAdmin ? (
                  <>
                    <Typography sx={{ marginBottom: 0.5 }}>
                      <Button
                        onClick={() => {
                          setMessagePopupVisible(true);
                        }}
                        sx={{ width: "48%", fontSize: 10 }}
                        variant="outlined"
                      >
                        Додати +
                      </Button>

                      <Button
                        variant="contained"
                        onClick={(e) => {
                          const currText = e.target.textContent;
                          e.target.textContent = "Зберігаю..";
                          e.target.setAttribute("disabled", "disabled");
                          e.target.style.opacity = 0.6;
                          (async () => {
                            await updateMessages();
                            e.target.textContent = "Збережено";
                            setTimeout(() => {
                              e.target.textContent = currText;
                              e.target.removeAttribute("disabled");
                              e.target.style.opacity = 1;
                            }, 1000);
                          })();
                        }}
                        sx={{ width: "48%", fontSize: 10 }}
                      >
                        Зберегти
                      </Button>
                    </Typography>
                  </>
                ) : (
                  ""
                )}

                {messages.map((message, index) => (
                  <Card
                    onClick={() => {
                      setMessagePopupVisible(true);
                      setEditMessage(message);
                    }}
                    key={message.order}
                    sx={{ marginBottom: 0.5, position: "relative" }}
                  >
                    <CardContent sx={{ textAlign: "left", padding: 1, paddingBottom: "15px!important" }}>
                      <Typography variant="p" sx={{ fontSize: 14 }}>
                        {message.text}
                      </Typography>
                    </CardContent>
                    {isAdmin ? (
                      <Box
                        sx={{
                          display: "flex",
                          position: "absolute",
                          top: 0,
                          right: 0,
                          height: "100%",
                          "& .MuiButtonGroup-root": {
                            boxShadow: "none",
                          },
                          "& button.MuiButtonGroup-grouped": {
                            m: 1,
                            padding: 0,
                            width: 20,
                            minWidth: "unset",
                            height: 20,
                            margin: 0,
                            opacity: 0.8,
                            borderBottom: "1px solid",
                          },
                        }}
                      >
                        <ButtonGroup
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          sx={{ display: "flex", height: "100%", justifyContent: "space-around" }}
                          orientation="vertical"
                          aria-label="vertical outlined button group"
                        >
                          {index ? (
                            <Button
                              key="up"
                              onClick={() => {
                                setMessages(JSON.parse(JSON.stringify(moveItemInArray(messages, index, "up"))));
                              }}
                            >
                              <KeyboardArrowUpIcon fontSize="10" />
                            </Button>
                          ) : (
                            ""
                          )}

                          {index < messages.length - 1 ? (
                            <Button
                              key="down"
                              onClick={() => {
                                setMessages(JSON.parse(JSON.stringify(moveItemInArray(messages, index, "down"))));
                              }}
                            >
                              <KeyboardArrowDownIcon fontSize="10" />
                            </Button>
                          ) : (
                            ""
                          )}
                        </ButtonGroup>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Card>
                ))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </div>
      {isAdmin ? <AdminView /> : <UserView />}
      <div className="App-footer">
        <BottomNavigation showLabels>
          <BottomNavigationAction
            sx={{ minWidth: 200 }}
            label="Політика конфіденційності"
            onClick={() => {
              setPrivacyPolicyPopupVisible(true);
              window.location.hash = "#privacy-policy";
            }}
          />
        </BottomNavigation>
      </div>
    </div>
  ) : (
    ""
  );
}

export default App;
