import React from "react";
import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import { Scheduler } from "@aldabil/react-scheduler";
import {
  resources,
  resourceFields,
  adminDay,
  fields,
  translations,
  view,
  recourseHeaderComponent,
} from "../common/calendar-settings";
import { createEvent, deleteEvent } from "../graphql/mutations";
import { listEvents } from "../graphql/queries";
import uk from "date-fns/locale/uk";
import { Button } from "@aws-amplify/ui-react";
import PaidIcon from "@mui/icons-material/Paid";
import Typography from "@mui/material/Typography";
import { EventStatus } from "../common/constants";

function AdminView() {
  async function getEvents(filter = {}, defaultFilter = { status: { eq: EventStatus.APPROVED } }) {
    filter = { ...filter, ...defaultFilter };
    const eventData = await API.graphql(graphqlOperation(listEvents, { filter }));
    return eventData.data.listEvents.items.map((item) => ({
      ...item,
      start: new Date(Date.parse(item.start)),
      end: new Date(Date.parse(item.end)),
      payment_successfull: item.payment_successfull ? "true" : "false",
    }));
  }

  async function fetchEvents(remoteQuery) {
    try {
      const events = await getEvents({
        start: { gt: remoteQuery.start.toISOString() },
        end: { lt: remoteQuery.end.toISOString() },
      });
      return new Promise((res) => {
        res(events);
      });
    } catch (err) {
      console.log("error fetching events:", err);
    }
  }

  async function putEvent(event, action) {
    try {
      if (event["event_id"]) {
        await removeEvent(event["event_id"]);
      }
      event["event_id"] = event["event_id"] || uuidv4();
      event["repeat_id"] = event["repeat_id"] || event["event_id"];
      event["status"] = EventStatus.APPROVED;
      await API.graphql(graphqlOperation(createEvent, { input: event }));
      if (event.repeat > 1) {
        const baseStartDate = event.start;
        const baseEndDate = event.end;
        for (let i = 1; i < event.repeat; i++) {
          baseStartDate.setDate(baseStartDate.getDate() + 7);
          baseEndDate.setDate(baseEndDate.getDate() + 7);
          await putEvent(
            {
              ...event,
              repeat_id: event.event_id,
              repeat: 1,
              event_id: uuidv4(),
              start: baseStartDate,
              end: baseEndDate,
            },
            "create"
          );
        }
      }
    } catch (err) {
      console.log("error creating event:", err);
    }
    return new Promise((res) => {
      res({ ...event });
    });
  }

  async function removeEvent(eventId, removeRelated = true) {
    try {
      if (eventId) {
        await API.graphql(
          graphqlOperation(deleteEvent, {
            input: {
              event_id: eventId,
            },
          })
        );
        if (removeRelated) {
          const relatedEvents = await getEvents({ repeat_id: { eq: eventId } });
          await Promise.all(
            relatedEvents.map(async (event) => {
              await removeEvent(event.event_id, false);
            })
          );
        }
      }
    } catch (err) {
      console.log("error removing event:", err);
    }
    return new Promise((res) => {
      res(eventId);
    });
  }

  const eventRenderer = ({ event, ...props }) => {
    console.log(event);
    return (
      <Button
        {...props}
        style={{
          color: "#fff",
          background: event.color,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          fontWeight: 400,
          fontSize: 12,
          border: "0",
          textAlign: "left",
          padding: 5,
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", bottom: "-4px", right: "0" }}>
          <span
            style={{
              color: event.payment_successfull === "true" ? "#e4ff00" : "red",
              display: event.payment_successfull === "true" ? "inline-block" : "none",
            }}
          >
            <PaidIcon fontSize="small" />
          </span>
        </div>
        <Typography
          sx={{
            fontWeight: 400,
            fontSize: 12,
            verticalAlign: "middle",
          }}
        >
          {event.title}
        </Typography>
      </Button>
    );
  };

  return (
    <div className="App-scheduler-adminView">
      <Scheduler
        view={view}
        resources={resources}
        resourceFields={resourceFields}
        day={{ ...adminDay, cellRenderer: null }}
        month={null}
        week={null}
        fields={fields}
        translations={translations}
        hourFormat="24"
        timeZone="Europe/Kyiv"
        locale={uk}
        getRemoteEvents={fetchEvents}
        onConfirm={putEvent}
        onDelete={removeEvent}
        recourseHeaderComponent={recourseHeaderComponent}
        stickyNavitation={true}
        eventRenderer={eventRenderer}
      />
    </div>
  );
}

export default AdminView;
