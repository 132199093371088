import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify, I18n } from "aws-amplify";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";

import { translations, Authenticator } from "@aws-amplify/ui-react";
I18n.putVocabularies(translations);
I18n.setLanguage("ua");

I18n.putVocabularies({
  ua: {
    "Enter your email": "Email",
    Name: "Прізвище, Ім'я",
    "Password must have at least 8 characters": "Пароль має мати довжину мінімум 8 символів",
    "Enter your Phone Number": "Введіть ваш номер телефону",
    "Enter your Password": "Введіть ваш пароль",
    "Enter your Name": "Введіть ваше прізвище, ім'я",
    "Please confirm your Password": "Підтвердіть ваш пароль",
  },
});

Amplify.configure(awsExports);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <App />
    </Authenticator.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
