const EventStatus = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  DECLINED: "DECLINED",
};

const CourtPrices = {
  RENT: 400,
};

export { EventStatus, CourtPrices };
